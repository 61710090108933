import React from 'react'
import { render } from 'react-dom'
import { Router, Route, IndexRoute, browserHistory } from 'react-router'
import * as firebase from 'firebase'

import qrcode from './qrcode'


let QRCode = React.createClass({
    getInitialState() {
        return {qrImg: null};
    },
    componentDidMount() {
        let qr = qrcode(4, 'M');
        console.log(this.props.url);
        qr.addData(this.props.url);
        qr.make();
        this.setState({ qrImg: qr.createImgTag(8, 1) });
    },
    render() {
        return (
            <div className="qrcode" dangerouslySetInnerHTML={{__html: this.state.qrImg}}>
            </div>
        );
    }
});

let NoteForm = React.createClass({
    handleKey(event) {
        if (event.which == 13 && !event.shiftKey) {
            this.props.onSubmit(this.refs.textArea.value);
            this.refs.textArea.value = '';
        }
    },
    render() {
        return (
            <div className="text-input">
                <form onSubmit={this.handleSubmit}>
                    <textarea ref="textArea"
                              onKeyUp={this.handleKey}
                              rows="3" autoFocus>
                    </textarea>
                </form>
            </div>
        );
    }
})

let Notes = ({ notes, onSubmit }) => {
    return (
        <div className="notes">
            {notes.map(n => <p key={n.key}>{n.content}</p>)}
            <NoteForm onSubmit={onSubmit} />
        </div>
    );
};


let App = React.createClass({
    getInitialState() {
        return { isAuthenticated: false };
    },
    componentDidMount() {
        let auth = firebase.auth();
        let self = this;
        auth.onAuthStateChanged(function(user) {
            if (user === null)
                firebase.auth().signInAnonymously();
            else
                self.setState({ isAuthenticated: true });
        });
    },
    render() {
        return (
            <div className="app">
                {this.state.isAuthenticated ? this.props.children : null}
            </div>
        );
    }
})

let Home = React.createClass({
    componentDidMount() {
        let pad = firebase.database().ref('pads').push();
        browserHistory.push('/' + pad.key);
    },
    render() {
        return null;
    }
});

let Pad = React.createClass({
    getInitialState() {
        return {
            notes: []
        };
    },
    handleNotesChange(snapshot) {
        let notes = [];
        snapshot.forEach(function(note) {
            notes.push({key: note.key, content: note.val().content});
        });
        this.setState({ notes: notes });
    },
    componentDidMount() {
        let padId = this.props.params.padId;
        firebase.database().ref(`pads/${padId}/notes`).on('value', this.handleNotesChange);
    },
    handleAddNote(text) {
        let padId = this.props.params.padId;
        firebase.database().ref(`pads/${padId}/notes`).push({
            content: text
        });
    },
    render() {
        return (
            <div className="app">
                <Notes notes={this.state.notes}
                       onSubmit={this.handleAddNote} />
                <div className="sidebar">
                    <QRCode url={document.location.href} />
                </div>
            </div>
        );
    }
});



var config = {
    apiKey: "AIzaSyBVLxLDvv1uV8Y5JTnqb5D6oKFrfmGDEm0",
    authDomain: "scratchpad-efe62.firebaseapp.com",
    databaseURL: "https://scratchpad-efe62.firebaseio.com",
    storageBucket: "",
    messagingSenderId: "71575119754"
};
firebase.initializeApp(config);


render(
    <Router history={browserHistory}>
        <Route path="/" component={App}>
            <IndexRoute component={Home} />
            <Route path=":padId" component={Pad} />
        </Route>
    </Router>,
    document.getElementById('container')
);
